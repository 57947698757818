var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "officialAccountBind" },
    [
      _c("p", [
        _vm._v(
          "为了更加方便接收消息,请您输入注册的时候所填写的账号密码进行绑定！！！"
        ),
      ]),
      _c(
        "van-form",
        {
          ref: "registrationVerification",
          staticStyle: { "margin-top": "50px" },
          on: { submit: _vm.onSubmit },
        },
        [
          _c("van-field", {
            attrs: {
              label: "账号",
              "left-icon": "phone-o",
              type: "tel",
              placeholder: "请输入手机号码",
              rules: _vm.formRyles.phoneNumber,
            },
            model: {
              value: _vm.ruleForm.phoneNumber,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "phoneNumber", $$v)
              },
              expression: "ruleForm.phoneNumber",
            },
          }),
          _c("van-field", {
            attrs: {
              label: "密码",
              "left-icon": "phone-o",
              type: "password",
              placeholder: "请输入密码",
              rules: _vm.formRyles.password,
            },
            model: {
              value: _vm.ruleForm.password,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "password", $$v)
              },
              expression: "ruleForm.password",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }