<template>
	<div class="officialAccountBind">
		<p>为了更加方便接收消息,请您输入注册的时候所填写的账号密码进行绑定！！！</p>
		<van-form style="margin-top: 50px" ref="registrationVerification" @submit="onSubmit">
     <van-field
				label="账号"
				v-model="ruleForm.phoneNumber"
				left-icon="phone-o"
				type="tel"
				placeholder="请输入手机号码"
				:rules="formRyles.phoneNumber"
				>
			</van-field>

			 <van-field
				label="密码"
				v-model="ruleForm.password"
				left-icon="phone-o"
				type="password"
				placeholder="请输入密码"
				:rules="formRyles.password"
				>
			</van-field>
		</van-form>
	</div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
	name:  '',
	components: {
		
	},
	// 定义属性
	data() {
		return {
			ruleForm: {
				phoneNumber: '',
        password: ''
			},
			formRyles: {
				// 手机号校验规则
        phoneNumber: [
          { required: true, message: '请填手机号' },
          {
            pattern: /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/,
            message: '手机格式错误'
          }
        ],
				//昵称检测
				password: [ 
					{require: true, message: '请填写密码'},
					{
            validator: (val) => {
							if(val === '') return false
							else return true 
						},
            message: '密码不能为空'
          } 
				]
			}
		}
	},
	// 计算属性，会监听依赖属性值随之变化
	computed: {},
	// 监控data中的数据变化
	watch: {},
	// 方法集合
	methods: {
		_getCode() {
			var STATE = window.location.search.replace('?', '');
			STATE = encodeURIComponent(STATE)
			window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?'+
			'appid=wx21369ca0a30f664c&redirect_uri=https://www.test.register.dijie360.com/OfficialAccountBind'+
			'&response_type=code&scope=snsapi_userinfo&state=' + STATE + '#wechat_redirect'
		}
	},
	// 生命周期 - 创建完成（可以访问当前this实例）
	created() {
		
	},
	// 生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {
		this._getCode()
	},
	beforeCreate() {}, // 生命周期 - 创建之前
	beforeMount() {}, // 生命周期 - 挂载之前
	beforeUpdate() {}, // 生命周期 - 更新之前
	updated() {}, // 生命周期 - 更新之后
	beforeDestroy() {}, // 生命周期 - 销毁之前
	destroyed() {}, // 生命周期 - 销毁完成
	activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
	.officialAccountBind {
		padding: 15px;
		>p {
			color: rgba(153, 153, 153, 1);
			font-size: 13px;
			text-align: left;
			font-family: PingFangSC-regular;
		}
	}
</style>